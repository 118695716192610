import React, { Component } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap"
import { Button, IconButton } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from "axios";
import Select from 'react-select'
import Bardcode from './Bardcode'
import ReactToPrint from 'react-to-print';
import SaleReport from "../../Report/SaleReport/SaleAFReport";
import SaleReportThermal from "../../Report/SaleReport/SaleReportThermal";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Swal from "sweetalert2";

const ServiceTypeOption = [
    { value: 'Express', label: 'Express' },
    { value: 'Premium', label: 'Premium' }
]

const RepairTypeList = [
    { value: 'Phone', label: 'Phone' },
    { value: 'Tablet', label: 'Tablet' },
    { value: 'Laptop', label: 'Laptop' },
    { value: 'Gaming Console', label: 'Gaming Console' }
]

function subtotal(items) {
    return items.map(({ PC_REP_CURR_COST }) => +PC_REP_CURR_COST).reduce((sum, i) => +sum + +i, 0);
}

class JobForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            RepairModelList: [],
            RepairBrandList: [],
            RepairServiceList: [],
            AllServiceData: [],
            TempNotes: [],

            jobData: {
                PC_REP_ID: "",
                PC_REP_BARCODE: "",
                PC_REP_TYPE: "",
                PC_REP_MODEL: "",
                PC_REP_BRAND: "",
                PC_REP_IMSR: "",
                PC_REP_OTHER_INFO: "",
                PC_REP_LIST: [],
                PC_REP_TOTAL_COST: "0",
                PC_REP_DEP_TYPE: "Cash",
                PC_REP_DEP: "0",
                PC_REP_DEP_DATE: "",
                PC_REP_REM_AM_TYPE: "Cash",
                PC_REP_REM_AM: "0",
                PC_REP_REM_AM_DATE: "",
                PC_REP_REM_BAL: "0",
                PC_REP_SHOP: "",
                PC_REP_STATUS: "",
                PC_REP_DATA_TYPE: "",
                PC_REP_NOTES: [],
                PC_REP_APP_DATE: "",
                PC_REP_APP_TIME: "",
                PC_REP_REF_NOTES: "",
                PC_REP_REF_AMT: "0",
                PC_CUST_FR_NAME: "",
                PC_CUST_LT_NAME: "",
                PC_CUST_PHONE: "",
                PC_CUST_EMAIL: "",
                PC_CUST_HSST_NO: "",
                PC_CUST_POST_CODE: "",
                PC_CUST_ADD_INFO: "",
                PC_REP_CREATED_DATE: "",
                PC_REP_UPDATED_DATE: ""
            },

            showReport: false,
            showPrint: false,



            EMAIL_TO: "",

            value: "PC29446",



            width: 1,
            height: 60,
            displayValue: true,
            text: "",
            fontOptions: "",
            font: "monospace",
            textAlign: "center",
            textPosition: "bottom",
            textMargin: 0,
            fontSize: 15,
            background: "#ffffff",
            lineColor: "#000000",
            margin: 10,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,

            OTHER_DATA: localStorage.getItem('OTHER_DATA')
        }
    }

    async UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.jobData !== nextProps.jobData) {
            if (Object.keys(nextProps.jobData).length !== 0) {
                this.resetState()
                let RepairServiceList = []
                let formData = {
                    REPAIR_TYPE: nextProps.jobData.PC_REP_TYPE?.value,
                    REPAIR_BRAND: nextProps.jobData.PC_REP_MODEL?.value,
                    REPAIR_DEVICE: nextProps.jobData.PC_REP_BRAND?.value,
                    PC_SHOP: localStorage.getItem('PC_GRP_ATT')
                }
                const getService = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShopService.php`, formData);
                let serv = getService.data
                if (serv.success === 1) {
                    for (let i = 0; i < serv.data.length; i++) {
                        const ele = serv.data[i];
                        if (
                            ele.PCSS_DEVICE_TYPE === nextProps.jobData['PC_REP_TYPE']?.value &&
                            ele.PCSS_DEVICE_BRAND === nextProps.jobData['PC_REP_MODEL']?.value &&
                            ele.PCSS_DEVICE_NAME === nextProps.jobData['PC_REP_BRAND']?.value
                        ) {
                            RepairServiceList.push({
                                value: ele.PCSS_SERVICE,
                                label: ele.PCSS_SERVICE
                            })
                        }
                    }
                }
                this.setState({
                    showReport: false,
                    showPrint: false,
                    jobData: nextProps.jobData,
                    RepairServiceList,
                    AllServiceData: serv.data,
                    value: nextProps.jobData.PC_REP_BARCODE
                })
            } else {
                this.resetState()
            }
        }
    }

    resetState() {
        this.setState({
            RepairModelList: [],
            RepairBrandList: [],
            RepairServiceList: [],
            AllServiceData: [],
            TempNotes: [],
            jobData: {
                PC_REP_ID: "",
                PC_REP_BARCODE: "",
                PC_REP_TYPE: "",
                PC_REP_MODEL: "",
                PC_REP_BRAND: "",
                PC_REP_IMSR: "",
                PC_REP_OTHER_INFO: "",
                PC_REP_LIST: [],
                PC_REP_TOTAL_COST: "0",
                PC_REP_DEP_TYPE: "Cash",
                PC_REP_DEP: "0",
                PC_REP_DEP_DATE: "",
                PC_REP_REM_AM_TYPE: "Cash",
                PC_REP_REM_AM: "0",
                PC_REP_REM_AM_DATE: "",
                PC_REP_REM_BAL: "0",
                PC_REP_SHOP: "",
                PC_REP_STATUS: "",
                PC_REP_DATA_TYPE: "",
                PC_REP_NOTES: [],
                PC_REP_APP_DATE: "",
                PC_REP_APP_TIME: "",
                PC_REP_REF_NOTES: "",
                PC_REP_REF_AMT: "0",
                PC_CUST_FR_NAME: "",
                PC_CUST_LT_NAME: "",
                PC_CUST_PHONE: "",
                PC_CUST_EMAIL: "",
                PC_CUST_HSST_NO: "",
                PC_CUST_POST_CODE: "",
                PC_CUST_ADD_INFO: "",
                PC_REP_CREATED_DATE: "",
                PC_REP_UPDATED_DATE: ""
            },
            showReport: false,
            showPrint: false
        })
    }

    async handleChange(fieldName, evnt) {
        let { jobData } = this.state
        let tempPaidAmt
        switch (fieldName) {
            case 'PC_REP_TYPE':
                jobData[fieldName] = evnt
                let deviceBrand = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetDeviceBrand.php`, { DEVICE: evnt.value });
                let RepairModelList = []
                if (deviceBrand.data.success === 1) {
                    let devices = deviceBrand.data.data;
                    for (let i = 0; i < devices.length; i++) {
                        const device = devices[i];
                        RepairModelList.push({
                            value: device.DEVICE_BRAND,
                            label: device.DEVICE_BRAND
                        })
                    }
                }
                jobData['PC_REP_MODEL'] = ""
                jobData['PC_REP_BRAND'] = ""
                jobData['PC_REP_IMSR'] = ""
                jobData['PC_REP_LIST'] = []
                this.setState({ RepairModelList })
                break;
            case 'PC_REP_MODEL':
                jobData[fieldName] = evnt
                let deviceType = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetDeviceName.php`, { BRAND: evnt.value, DEVICE: jobData['PC_REP_TYPE']?.value });
                let RepairBrandList = []
                if (deviceType.data.success === 1) {
                    let devices = deviceType.data.data;
                    for (let i = 0; i < devices.length; i++) {
                        const deviceName = devices[i];
                        RepairBrandList.push({
                            value: deviceName.DEVICE_NAME,
                            label: deviceName.DEVICE_NAME
                        })
                    }
                }

                jobData['PC_REP_BRAND'] = ""
                jobData['PC_REP_IMSR'] = ""
                jobData['PC_REP_LIST'] = []
                this.setState({ RepairBrandList })

                break;
            case 'PC_REP_BRAND':
                jobData[fieldName] = evnt
                let RepairServiceList = []
                let formData = {
                    REPAIR_TYPE: jobData['PC_REP_TYPE']?.value,
                    REPAIR_BRAND: jobData['PC_REP_MODEL']?.value,
                    REPAIR_DEVICE: evnt?.value,
                    PC_SHOP: localStorage.getItem('PC_GRP_ATT')
                }
                const getService = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShopService.php`, formData);
                let serv = getService.data
                if (serv.success === 1) {
                    for (let i = 0; i < serv.data.length; i++) {
                        const ele = serv.data[i];
                        if (
                            ele.PCSS_DEVICE_TYPE === jobData['PC_REP_TYPE']?.value &&
                            ele.PCSS_DEVICE_BRAND === jobData['PC_REP_MODEL']?.value &&
                            ele.PCSS_DEVICE_NAME === evnt?.value.trim()
                        ) {
                            RepairServiceList.push({
                                value: ele.PCSS_SERVICE,
                                label: ele.PCSS_SERVICE,
                                id: ele.PCSS_ID
                            })
                        }
                    }
                }
                jobData['PC_REP_IMSR'] = ""
                jobData['PC_REP_LIST'] = []
                this.setState({ RepairServiceList, AllServiceData: serv.data })
                break;

            case 'PC_REP_DEP':
                jobData[fieldName] = evnt.target.value
                jobData['PC_REP_REM_BAL'] = jobData['PC_REP_TOTAL_COST'] - evnt.target.value
                break;

            case 'PC_REP_TOTAL_COST':
                jobData[fieldName] = evnt.target.value
                jobData['PC_REP_REM_BAL'] = evnt.target.value - jobData['PC_REP_DEP']
                break;

            case 'PC_REP_REM_AM':
                jobData[fieldName] = evnt.target.value
                tempPaidAmt = +evnt.target.value + +jobData['PC_REP_DEP']
                jobData['PC_REP_REM_BAL'] = jobData['PC_REP_TOTAL_COST'] - tempPaidAmt
                break;

            default:
                jobData[fieldName] = evnt.target.value
                break;
        }
        this.setState({ jobData })

    }

    handleAddNote() {
        let { TempNotes } = this.state
        TempNotes.push({
            NoteDateTime: new Date().toLocaleString(),
            NoteText: "",
            NoteBy: localStorage.getItem('PC_USR_NAME')
        })
        this.setState({ TempNotes })
    }

    handleChangeNotes(indx, fieldName, evnt) {
        let { TempNotes } = this.state
        TempNotes[indx][fieldName] = evnt.target.value
        this.setState({ TempNotes })
    }

    handleRemoveNote(indx) {
        let { TempNotes } = this.state
        TempNotes.splice(indx, 1)
        this.setState({ TempNotes })
    }

    validateForm(JobStatus) {
        let { jobData } = this.state;
        if (JobStatus === 'Finish') {
            if (jobData.PC_REP_REM_BAL !== '0' && jobData.PC_REP_REM_BAL !== 0) {
                Swal.fire(
                    'Error',
                    'Balance Should be equal',
                    'error'
                )
                return false
            }
        }
        if (jobData.PC_REP_TYPE === '' || jobData.PC_REP_TYPE === {}) {
            Swal.fire(
                'Error',
                'Please Select Device Type',
                'error'
            )
            return false
        }
        if (jobData.PC_REP_MODEL === '' || jobData.PC_REP_MODEL === {}) {
            Swal.fire(
                'Error',
                'Please Select Brand',
                'error'
            )
            return false
        }
        if (jobData.PC_REP_BRAND === '' || jobData.PC_REP_BRAND === {}) {
            Swal.fire(
                'Error',
                'Please Select Model',
                'error'
            )
            return false
        }
        if (jobData.PC_REP_IMSR === '') {
            Swal.fire(
                'Error',
                'Please Enter Serial.No',
                'error'
            )
            return false
        }
        if (jobData.PC_REP_LIST.length === 0) {
            Swal.fire(
                'Error',
                'Please Enter Any Repair',
                'error'
            )
            return false
        }

        if (
            jobData.PC_CUST_FR_NAME === '' ||
            jobData.PC_CUST_LT_NAME === '' ||
            jobData.PC_CUST_PHONE === '' ||
            jobData.PC_CUST_EMAIL === '' ||
            jobData.PC_CUST_HSST_NO === '' ||
            jobData.PC_CUST_POST_CODE === ''
        ) {
            Swal.fire(
                'Error',
                'Please Enter Complete Customer Information',
                'error'
            )
            return false
        }
        return true
    }

    handleChangeEmail(evt) {
        this.setState({ [evt.target.name]: evt.target.value })
    }

    async handleSubmitRequest(JobStatus, buttonOption = '') {
        if (!this.validateForm(JobStatus)) return
        let JobResp;
        let { jobData } = this.state
        let formData = {
            PC_REP_ID: jobData.PC_REP_ID,
            PC_REP_TYPE: JSON.stringify(jobData.PC_REP_TYPE),
            PC_REP_MODEL: JSON.stringify(jobData.PC_REP_MODEL),
            PC_REP_BRAND: JSON.stringify(jobData.PC_REP_BRAND),
            PC_REP_IMSR: jobData.PC_REP_IMSR,
            PC_REP_OTHER_INFO: jobData.PC_REP_OTHER_INFO,
            PC_REP_LIST: JSON.stringify(jobData.PC_REP_LIST),
            PC_REP_TOTAL_COST: jobData.PC_REP_TOTAL_COST,
            PC_REP_DEP_TYPE: jobData.PC_REP_DEP_TYPE,
            PC_REP_DEP: jobData.PC_REP_DEP,
            PC_REP_DEP_DATE: new Date().toLocaleDateString(),
            PC_REP_REM_AM_TYPE: jobData.PC_REP_REM_AM_TYPE,
            PC_REP_REM_AM: jobData.PC_REP_REM_AM,
            PC_REP_REM_AM_DATE: new Date().toLocaleDateString(),
            PC_REP_REM_BAL: jobData.PC_REP_REM_BAL,
            PC_REP_SHOP: localStorage.getItem('PC_GRP_ATT'),
            PC_REP_STATUS: JobStatus,
            PC_REP_DATA_TYPE: this.props.eligibleFor === 'Available' ? 'Online Customer' : 'Walk In',
            PC_REP_NOTES: JSON.stringify([...jobData.PC_REP_NOTES, ...this.state.TempNotes]),
            PC_REP_APP_DATE: new Date().toLocaleDateString(),
            PC_REP_APP_TIME: new Date().toLocaleTimeString(),
            PC_REP_REF_NOTES: jobData.PC_REP_REF_NOTES,
            PC_REP_REF_AMT: jobData.PC_REP_REF_AMT,
            PC_CUST_FR_NAME: jobData.PC_CUST_FR_NAME,
            PC_CUST_LT_NAME: jobData.PC_CUST_LT_NAME,
            PC_CUST_PHONE: jobData.PC_CUST_PHONE,
            PC_CUST_EMAIL: jobData.PC_CUST_EMAIL,
            PC_CUST_HSST_NO: jobData.PC_CUST_HSST_NO,
            PC_CUST_POST_CODE: jobData.PC_CUST_POST_CODE,
            PC_CUST_ADD_INFO: jobData.PC_CUST_ADD_INFO
        }
        if (JobStatus === 'Refund') {
            Swal.fire({
                title: 'Do you want to refund?',
                showCancelButton: true,
                confirmButtonText: 'Refund'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    JobResp = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCJob.php`, formData);
                    if (JobResp.data.success === 1) {
                        if (buttonOption === 'Barcode') {
                            this.setState({ showReport: true, value: JobResp.data.data, showPrint: false })
                        }
                        else if (buttonOption === 'Report') {
                            this.setState({ showReport: false, showPrint: true })
                        }
                        else {
                            this.setState({ showReport: false, showPrint: false })
                            this.props.closeModal()
                        }
                    }
                }
            })
            return false
        }
        JobResp = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCJob.php`, formData);
        let mJobResp = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/UpdateServices.php`, JSON.stringify(jobData.PC_REP_LIST));
        if (JobResp.data.success === 1) {
            if (buttonOption === 'Barcode') {
                this.setState({ showReport: true, value: JobResp.data.data, showPrint: false })
            }
            else if (buttonOption === 'Report') {
                this.setState({ showReport: false, showPrint: true })
            }
            else {
                this.setState({ showReport: false, showPrint: false })
                this.props.closeModal()
            }
        }
    }

    handleCloseRequest() {
        this.props.closeModal()
    }

    handleRePrint() {
        this.setState({ showReport: true })
    }

    handleAddService() {
        let { jobData } = this.state
        jobData['PC_REP_LIST'].push({
            PC_REP_PROB: "",
            PC_REP_SER_TYPE: { value: 'Express', label: 'Express' },
            PC_REP_EST_COST: 0,
            PC_REP_DISCOUNT: 0,
            PC_REP_CURR_COST: 0
        })
        this.setState({ jobData })
    }

    handleChangeServices(indx, fieldName, evnt) {
        let { AllServiceData, jobData } = this.state;
        let myPercentage, myBalance, fakeBalance
        switch (fieldName) {
            case 'PC_REP_PROB':
                jobData['PC_REP_LIST'][indx][fieldName] = evnt
                jobData['PC_REP_LIST'][indx]['PC_REP_DISCOUNT'] = 0
                if (AllServiceData) {
                    for (let i = 0; i < AllServiceData.length; i++) {
                        const servData = AllServiceData[i];
                        if (servData?.PCSS_SERVICE === evnt?.value) {
                            if (jobData['PC_REP_LIST'][indx]['PC_REP_SER_TYPE']?.value === 'Express') {
                                jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] = +servData.PCSS_SERVICE_COST;
                                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = +servData.PCSS_SERVICE_COST;
                            } else {
                                jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] = +servData.PCSS_SERVICE_PREM;
                                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = +servData.PCSS_SERVICE_PREM;
                            }
                        }
                    }
                }
                break;

            case 'PC_REP_SER_TYPE':
                jobData['PC_REP_LIST'][indx][fieldName] = evnt
                jobData['PC_REP_LIST'][indx]['PC_REP_DISCOUNT'] = 0
                if (AllServiceData) {
                    for (let i = 0; i < AllServiceData.length; i++) {
                        const servData = AllServiceData[i];
                        if (servData?.PCSS_SERVICE === jobData['PC_REP_LIST'][indx]['PC_REP_PROB']?.value) {
                            if (evnt?.value === 'Express') {
                                jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] = +servData.PCSS_SERVICE_COST;
                                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = +servData.PCSS_SERVICE_COST;
                            } else {
                                jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] = +servData.PCSS_SERVICE_PREM;
                                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = +servData.PCSS_SERVICE_PREM;
                            }
                        }
                    }
                }
                break;

            case 'PC_REP_DISCOUNT':
                jobData['PC_REP_LIST'][indx][fieldName] = evnt.target.value

                myPercentage = evnt.target.value / 100
                myBalance = (jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] * myPercentage)
                fakeBalance = jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] - myBalance
                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = fakeBalance
                break;

            case 'PC_REP_EST_COST':
                jobData['PC_REP_LIST'][indx][fieldName] = evnt.target.value
                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = evnt.target.value
                jobData['PC_REP_LIST'][indx]['PC_REP_DISCOUNT'] = 0
                break;

            default:
                jobData['PC_REP_LIST'][indx][fieldName] = evnt.target.value
                break;
        }
        let subTotals = subtotal(jobData['PC_REP_LIST'])
        jobData['PC_REP_TOTAL_COST'] = (subTotals).toString();
        jobData['PC_REP_REM_BAL'] = (subTotals - jobData['PC_REP_DEP']).toString();
        this.setState({ jobData })
    }

    handleRemoveService(indx) {
        let { jobData } = this.state
        jobData['PC_REP_LIST'].splice(indx, 1)
        let subTotals = subtotal(jobData['PC_REP_LIST'])
        jobData['PC_REP_TOTAL_COST'] = (subTotals).toString();
        jobData['PC_REP_REM_BAL'] = (subTotals - jobData['PC_REP_DEP']).toString();
        this.setState({ jobData })
    }

    handleRePrintReport() {
        this.setState({ showPrint: true })
    }

    handleValidate() {
        if (this.state.EMAIL_TO === "") {
            Swal.fire(
                'Error',
                'Please Enter Email Address',
                'error'
            )
            return false
        }
        return true
    }

    async handleSendEmail() {

        if (!this.handleValidate()) return

        let formData = {
            SHOP_FROM: localStorage.getItem('PC_GRP_ATT'),
            SHOP_ADDRESS: localStorage.getItem('SP_ADDRESS'),
            INVOICE_NO: this.state.jobData.PC_REP_BARCODE,
            EMAIL_TO: this.state.EMAIL_TO,
            PRODUCT_NAME: `${this.state.jobData.PC_REP_MODEL?.value} ${this.state.jobData.PC_REP_BRAND?.value}`,
            SERVICE: JSON.stringify(this.state.jobData?.PC_REP_LIST)
        }
        let getSaleInvoiceResult = await axios.post(`${process.env.REACT_APP_BASE_URL}mail/SendMailJob.php`, formData);
        if (getSaleInvoiceResult.data.status === 201) {
            Swal.fire(
                'Success',
                'Email has been send!!',
                'success'
            ).then(() => {
                this.setState({ EMAIL_TO: "" })
                this.handleCloseRequest()
            })
        }
    }

    render() {
        let { eligibleFor } = this.props
        let { jobData } = this.state;
        let serv = ""
        let itemService = jobData['PC_REP_LIST']

        for (let i = 0; i < itemService.length; i++) {
            const ser = itemService[i];
            serv += `${ser.PC_REP_PROB.value}, `
        }

        serv = serv.replace(/,\s*$/, "");

        return (
            <Modal
                show={this.props.openModal}
                onHide={this.props.closeModal}
                backdrop="static"
                keyboard={false}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Request Job</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ height: '75vh', overflow: 'auto' }}>
                    {this.state.showReport !== true ?
                        this.state.showPrint !== true ?
                            <Container>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Device Type</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Select
                                                name="PC_REP_TYPE"
                                                // styles={{
                                                //     container: (base) => ({
                                                //         ...base,
                                                //         backgroundColor: 'red',
                                                //         padding: 1,
                                                //     })
                                                // }}
                                                isDisabled={eligibleFor === 'Shop Request' ? false : true}
                                                options={RepairTypeList}
                                                value={jobData['PC_REP_TYPE']}
                                                onChange={this.handleChange.bind(this, 'PC_REP_TYPE')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Brand</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Select
                                                name="PC_REP_MODEL"
                                                style={{ padding: "0.25rem" }}
                                                isDisabled={eligibleFor === 'Shop Request' ? false : true}
                                                options={this.state.RepairModelList}
                                                value={jobData['PC_REP_MODEL']}
                                                onChange={this.handleChange.bind(this, 'PC_REP_MODEL')}

                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} >
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Model</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Select
                                                name="PC_REP_BRAND"
                                                style={{ padding: "0.25rem" }}
                                                isDisabled={eligibleFor === 'Shop Request' ? false : true}
                                                options={this.state.RepairBrandList}
                                                value={jobData['PC_REP_BRAND']}
                                                onChange={this.handleChange.bind(this, 'PC_REP_BRAND')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} >
                                        <Form.Group className="mb-3" >
                                            <Form.Label>IMEI / Serial.No</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name="PC_REP_IMSR"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' || eligibleFor === 'Available' || eligibleFor === 'In Process' ? false : true}
                                                value={jobData['PC_REP_IMSR']}
                                                onChange={this.handleChange.bind(this, "PC_REP_IMSR")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} >
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Other Information</Form.Label>
                                            <Form.Control
                                                name="PC_REP_OTHER_INFO"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' ? false : true}
                                                type={"textarea"}
                                                value={jobData['PC_REP_OTHER_INFO']}
                                                onChange={this.handleChange.bind(this, "PC_REP_OTHER_INFO")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <legend style={{ borderBottom: '1px solid #1c1950' }} >Services</legend>
                                    <TableContainer className="mb-3">
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Repair</TableCell>
                                                    <TableCell>Service Type</TableCell>
                                                    <TableCell>Estimate Cost</TableCell>
                                                    <TableCell>Discount %</TableCell>
                                                    <TableCell>Current Cost</TableCell>
                                                    {eligibleFor === 'Shop Request' || eligibleFor === 'In Process' || eligibleFor === 'Available' ?
                                                        <TableCell align="right">
                                                            <IconButton onClick={this.handleAddService.bind(this)} >
                                                                <AddIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        : ""
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {jobData['PC_REP_LIST'].map((elem, indx) => (
                                                    <TableRow
                                                        key={indx}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <Form.Group>
                                                                <Select
                                                                    menuPortalTarget={document.body}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                    isDisabled={eligibleFor === 'Shop Request' || eligibleFor === 'In Process' || eligibleFor === 'Available' ? false : true}
                                                                    options={this.state.RepairServiceList}
                                                                    value={elem.PC_REP_PROB}
                                                                    onChange={this.handleChangeServices.bind(this, indx, "PC_REP_PROB")}
                                                                />
                                                            </Form.Group>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Form.Group>
                                                                <Select
                                                                    menuPortalTarget={document.body}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                    isDisabled={eligibleFor === 'Shop Request' || eligibleFor === 'In Process' || eligibleFor === 'Available' ? false : true}
                                                                    options={ServiceTypeOption}
                                                                    value={elem.PC_REP_SER_TYPE}
                                                                    onChange={this.handleChangeServices.bind(this, indx, "PC_REP_SER_TYPE")}
                                                                />
                                                            </Form.Group>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    value={elem.PC_REP_EST_COST}
                                                                    disabled={eligibleFor === 'Shop Request' || eligibleFor === 'In Process' || eligibleFor === 'Available' ? false : true}
                                                                    onChange={this.handleChangeServices.bind(this, indx, "PC_REP_EST_COST")}
                                                                />
                                                            </Form.Group>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    value={elem.PC_REP_DISCOUNT}
                                                                    disabled={eligibleFor === 'Shop Request' || eligibleFor === 'In Process' || eligibleFor === 'Available' ? false : true}
                                                                    onChange={this.handleChangeServices.bind(this, indx, "PC_REP_DISCOUNT")}
                                                                />
                                                            </Form.Group>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Form.Group>
                                                                <Form.Control
                                                                    disabled={true}
                                                                    value={elem.PC_REP_CURR_COST}
                                                                    onChange={this.handleChangeServices.bind(this, indx, "PC_REP_CURR_COST")}
                                                                />
                                                            </Form.Group>
                                                        </TableCell>
                                                        {eligibleFor === 'Shop Request' || eligibleFor === 'In Process' || eligibleFor === 'Available' ?
                                                            <TableCell align="right">
                                                                <IconButton onClick={this.handleRemoveService.bind(this, indx)} >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </TableCell>
                                                            :
                                                            ""
                                                        }
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <legend style={{ borderBottom: '1px solid #1c1950' }} >Accounts</legend>
                                    <Col md={12}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Total</Form.Label>
                                            <Form.Control
                                                name="PC_REP_TOTAL_COST"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' || eligibleFor === 'Available' ? false : true}
                                                value={jobData['PC_REP_TOTAL_COST']}
                                                onChange={this.handleChange.bind(this, "PC_REP_TOTAL_COST")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3" >
                                            <RadioGroup
                                                name="PC_REP_DEP_TYPE"
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                value={jobData['PC_REP_DEP_TYPE']}
                                                onChange={this.handleChange.bind(this, "PC_REP_DEP_TYPE")}
                                            >
                                                <FormControlLabel
                                                    disabled={eligibleFor === 'Shop Request' || eligibleFor === 'Available' ? false : true}
                                                    value="Cash" labelPlacement="top" control={<Radio />} label="Cash" />
                                                <FormControlLabel
                                                    disabled={eligibleFor === 'Shop Request' || eligibleFor === 'Available' ? false : true}
                                                    value="Card" labelPlacement="top" control={<Radio />} label="Card" />
                                            </RadioGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={9}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Deposit</Form.Label>
                                            <Form.Control
                                                name="PC_REP_DEP"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' || eligibleFor === 'Available' || eligibleFor === 'In Process' ? false : true}
                                                value={jobData['PC_REP_DEP']}
                                                onChange={this.handleChange.bind(this, "PC_REP_DEP")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {eligibleFor === 'Sale' || eligibleFor === 'Completed' || eligibleFor === 'Refund' ?
                                        <>
                                            <Col md={3}>
                                                <Form.Group className="mb-3" >
                                                    <RadioGroup
                                                        name="PC_REP_REM_AM_TYPE"
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        value={jobData['PC_REP_REM_AM_TYPE']}
                                                        onChange={this.handleChange.bind(this, "PC_REP_REM_AM_TYPE")}
                                                    >
                                                        <FormControlLabel
                                                            disabled={eligibleFor === 'Sale' ? false : true}
                                                            value="Cash" labelPlacement="top" control={<Radio />} label="Cash" />
                                                        <FormControlLabel
                                                            disabled={eligibleFor === 'Sale' ? false : true}
                                                            value="Card" labelPlacement="top" control={<Radio />} label="Card" />
                                                    </RadioGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col md={9}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Amount</Form.Label>
                                                    <Form.Control
                                                        name="PC_REP_REM_AM"
                                                        style={{ padding: "0.25rem" }}
                                                        disabled={eligibleFor === 'Sale' ? false : true}
                                                        value={jobData['PC_REP_REM_AM']}
                                                        onChange={this.handleChange.bind(this, "PC_REP_REM_AM")}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        ""
                                    }
                                    <Col md={12}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Balance</Form.Label>
                                            <Form.Control
                                                value={jobData['PC_REP_REM_BAL']}
                                                style={{ padding: "0.25rem" }}
                                                disabled={true}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {eligibleFor === 'Completed' || eligibleFor === 'Refund' ?
                                        <>
                                            <Col md={8}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Refund Note</Form.Label>
                                                    <Form.Control
                                                        name="PC_REP_REF_NOTES"
                                                        style={{ padding: "0.25rem" }}
                                                        type={"textarea"}
                                                        disabled={eligibleFor === 'Completed' ? false : true}
                                                        value={jobData['PC_REP_REF_NOTES']}
                                                        onChange={this.handleChange.bind(this, "PC_REP_REF_NOTES")}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={4}>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Refund Amount</Form.Label>
                                                    <Form.Control
                                                        name="PC_REP_REF_AMT"
                                                        style={{ padding: "0.25rem" }}
                                                        disabled={eligibleFor === 'Completed' ? false : true}
                                                        value={jobData['PC_REP_REF_AMT']}
                                                        onChange={this.handleChange.bind(this, "PC_REP_REF_AMT")}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </>
                                        :
                                        ""
                                    }
                                    <legend style={{ borderBottom: '1px solid #1c1950' }} >Customer Information</legend>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>First Name</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name="PC_CUST_FR_NAME"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' ? false : true}
                                                value={jobData['PC_CUST_FR_NAME']}
                                                onChange={this.handleChange.bind(this, "PC_CUST_FR_NAME")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Last Name</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name="PC_CUST_LT_NAME"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' ? false : true}
                                                value={jobData['PC_CUST_LT_NAME']}
                                                onChange={this.handleChange.bind(this, "PC_CUST_LT_NAME")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Phone</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name="PC_CUST_PHONE"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' ? false : true}
                                                value={jobData['PC_CUST_PHONE']}
                                                onChange={this.handleChange.bind(this, "PC_CUST_PHONE")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Email</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name="PC_CUST_EMAIL"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' ? false : true}
                                                value={jobData['PC_CUST_EMAIL']}
                                                onChange={this.handleChange.bind(this, "PC_CUST_EMAIL")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>House.No / Street Name</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name="PC_CUST_HSST_NO"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' ? false : true}
                                                value={jobData['PC_CUST_HSST_NO']}
                                                onChange={this.handleChange.bind(this, "PC_CUST_HSST_NO")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Post Code</Form.Label>
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                            <Form.Control
                                                name="PC_CUST_POST_CODE"
                                                style={{ padding: "0.25rem" }}
                                                disabled={eligibleFor === 'Shop Request' ? false : true}
                                                value={jobData['PC_CUST_POST_CODE']}
                                                onChange={this.handleChange.bind(this, "PC_CUST_POST_CODE")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Additional Information</Form.Label>
                                            <Form.Control
                                                name="PC_CUST_ADD_INFO"
                                                style={{ padding: "0.25rem" }}
                                                type={"textarea"}
                                                disabled={eligibleFor === 'Shop Request' ? false : true}
                                                value={jobData['PC_CUST_ADD_INFO']}
                                                onChange={this.handleChange.bind(this, "PC_CUST_ADD_INFO")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    {eligibleFor === 'In Process' ?
                                        <div>
                                            <legend style={{ borderBottom: '1px solid #1c1950' }} >Add Notes <span
                                                onClick={this.handleAddNote.bind(this)}
                                                style={{ float: 'right' }} ><AddIcon style={{ cursor: 'pointer' }} /></span> </legend>
                                            {jobData.PC_REP_NOTES.map((ele, indx) => {
                                                return (
                                                    <Row key={indx} style={{ alignItems: 'center' }} >
                                                        <Col md={3}>
                                                            <Form.Group className="mb-2" style={{ display: 'flex', alignItems: 'center' }} >
                                                                <Form.Control
                                                                    style={{ padding: "0.25rem" }}
                                                                    value={ele.NoteDateTime}
                                                                    disabled={true}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Form.Group className="mb-2" style={{ display: 'flex', alignItems: 'center' }} >
                                                                <Form.Control
                                                                    style={{ padding: "0.25rem" }}
                                                                    value={ele.NoteBy}
                                                                    disabled={true}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Form.Group className="mb-2" style={{ display: 'flex', alignItems: 'center' }} >
                                                                <Form.Control
                                                                    as={'textarea'}
                                                                    style={{ padding: "0.25rem" }}
                                                                    value={ele.NoteText}
                                                                    disabled={true}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                )
                                            })}

                                            <Row>
                                                {this.state.TempNotes.map((items, index) => {
                                                    return (
                                                        <Col md={12} key={index} >
                                                            <Form.Group className="mb-2" style={{ display: 'flex', alignItems: 'center' }} >
                                                                <Form.Control
                                                                    as={'textarea'}
                                                                    name={"NoteText"}
                                                                    style={{ padding: "0.25rem" }}
                                                                    value={items.NoteText}
                                                                    onChange={this.handleChangeNotes.bind(this, index, "NoteText")}
                                                                />
                                                                <RemoveIcon
                                                                    onClick={this.handleRemoveNote.bind(this, index)}
                                                                    style={{ cursor: 'pointer' }} />
                                                            </Form.Group>
                                                        </Col>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                        :
                                        ''
                                    }
                                </Row>
                            </Container>
                            :
                            <Container style={{ textAlign: 'center' }} >
                                <Row>
                                    <Col>
                                        <h1>Sale Job Finish</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h3>
                                            {`${jobData.PC_CUST_FR_NAME} ${jobData.PC_CUST_LT_NAME}`}
                                        </h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {`${jobData.PC_REP_MODEL.value} ${jobData.PC_REP_BRAND.value}`}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {serv}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h3>
                                            Amount: £ {+jobData.PC_REP_DEP + +jobData.PC_REP_REM_AM}
                                        </h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p style={{ fontSize: '10px', fontWeight: 'bold' }}>
                                            Thanks for visiting PLEASE KEEP YOUR RECEIPT as your PROOF OF PURCHASE (Customer Copy), Purchasing the
                                            goods from us means that you agree and accept these <strong>terms & conditions</strong>.</p>
                                    </Col>
                                </Row>
                                <SaleReport ref={el => (this.printAF = el)} jobData={jobData} OTHER_DATA={this.state.OTHER_DATA} />
                                <SaleReportThermal ref={el => (this.printThermal = el)} jobData={jobData} OTHER_DATA={this.state.OTHER_DATA} />
                            </Container>
                        :
                        <Container style={{ maxHeight: '50vh', overflowY: 'auto' }} >
                            <div id="mainDiv" ref={el => (this.printLabel = el)} >
                                <div>
                                    <Bardcode
                                        value={this.state.value}
                                        width={this.state.width}
                                        height={this.state.height}
                                        displayValue={this.state.displayValue}
                                        text={this.state.text}
                                        fontOptions={this.state.fontOptions}
                                        font={this.state.font}
                                        textAlign={this.state.textAlign}
                                        textPosition={this.state.textPosition}
                                        textMargin={this.state.textMargin}
                                        fontSize={this.state.fontSize}
                                        background={this.state.background}
                                        lineColor={this.state.lineColor}
                                        margin={this.state.margin}
                                        marginTop={this.state.marginTop}
                                        marginBottom={this.state.marginBottom}
                                        marginLeft={this.state.marginLeft}
                                        marginRight={this.state.marginRight}
                                    />
                                </div>
                                <div>
                                    <p style={{ marginBottom: '0', fontSize: '12px' }}>{localStorage.getItem('PC_GRP_ATT')}</p>
                                    <p style={{ marginBottom: '0', fontSize: '12px' }}>{`${jobData.PC_CUST_FR_NAME} ${jobData.PC_CUST_LT_NAME}`}</p>
                                    <p style={{ marginBottom: '0', fontSize: '12px' }}>{`${jobData.PC_REP_MODEL.value} ${jobData.PC_REP_BRAND.value}`}</p>
                                    <p style={{ marginBottom: '0', fontSize: '12px' }}>{serv}</p>
                                </div>
                            </div>
                        </Container>
                    }
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'space-between' }} >
                    <Button onClick={this.props.closeModal}>Close</Button>
                    {eligibleFor === "Shop Request" ?
                        this.state.showReport === true ?
                            <ReactToPrint
                                trigger={() => { return <Button>Print</Button>; }}
                                content={() => this.printLabel}
                            />
                            :
                            <Button onClick={this.handleSubmitRequest.bind(this, 'Available', 'Barcode')}>Proceed</Button>
                        :
                        eligibleFor === "In Process" ?
                            this.state.showReport === true ?
                                <ReactToPrint
                                    trigger={() => { return <Button>Print</Button>; }}
                                    content={() => this.printLabel}
                                />
                                :
                                <div>
                                    <Button onClick={this.handleRePrint.bind(this)}>Re Print</Button>
                                    <Button onClick={this.handleSubmitRequest.bind(this, 'In Process')}>Update</Button>
                                    <Button onClick={this.handleSubmitRequest.bind(this, 'BER')}>BER</Button>
                                    <Button onClick={this.handleSubmitRequest.bind(this, 'Completed')}>Completed</Button>
                                </div>
                            :
                            eligibleFor === 'Sale' ?
                                this.state.showPrint === true ?
                                    <div>
                                        <Form.Control
                                            name="EMAIL_TO"
                                            style={{ padding: "0.25rem", width: "100%" }}
                                            value={this.state.EMAIL_TO}
                                            placeholder="Enter Email"
                                            onChange={this.handleChangeEmail.bind(this)}
                                        />
                                        <Button onClick={this.handleSendEmail.bind(this)} >Send Email</Button>
                                        <ReactToPrint
                                            trigger={() => { return <Button>Print</Button>; }}
                                            content={() => this.printAF}
                                        />
                                        <ReactToPrint
                                            trigger={() => { return <Button>Print Thermal</Button>; }}
                                            content={() => this.printThermal}
                                        />
                                    </div>
                                    :
                                    <Button onClick={this.handleSubmitRequest.bind(this, 'Finish', 'Report')}>Finish</Button>
                                :
                                eligibleFor === 'Completed' ?
                                    this.state.showPrint === true ?
                                        <div>
                                            <Form.Control
                                                name="EMAIL_TO"
                                                style={{ padding: "0.25rem", width: "100%" }}
                                                value={this.state.EMAIL_TO}
                                                placeholder="Enter Email"
                                                onChange={this.handleChangeEmail.bind(this)}
                                            />
                                            <Button onClick={this.handleSendEmail.bind(this)} >Send Email</Button>
                                            <ReactToPrint
                                                trigger={() => { return <Button>Print</Button>; }}
                                                content={() => this.printAF}
                                            />
                                            <ReactToPrint
                                                trigger={() => { return <Button>Print Thermal</Button>; }}
                                                content={() => this.printThermal}
                                            />
                                        </div>
                                        :
                                        <div>
                                            <Button onClick={this.handleRePrintReport.bind(this)}>Re Print</Button>
                                            <Button onClick={this.handleSubmitRequest.bind(this, 'Refund')}>Refund</Button>
                                        </div>
                                    :
                                    eligibleFor === 'Available' ?
                                        <Button onClick={this.handleSubmitRequest.bind(this, 'In Process')}>Continue</Button>
                                        :
                                        ""
                    }
                </Modal.Footer>
            </Modal >
        )
    }
}

export default JobForm;