import React from "react";
import { Form, Container, Row, Col } from "react-bootstrap";
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Button,
    IconButton
} from "@mui/material";
import axios from "axios";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import ShowReport from "./ShowReport";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Swal from "sweetalert2";

const steps = [
    "Setup Your Device",
    "Service",
    "Book An Appointment",
    "Personal Information"
];

const ServiceTypeOption = [
    { value: 'Express', label: 'Express' },
    { value: 'Premium', label: 'Premium' }
]

const RepairTypeList = [
    { value: 'Phone', label: 'Phone' },
    { value: 'Tablet', label: 'Tablet' },
    { value: 'Laptop', label: 'Laptop' },
    { value: 'Gaming Console', label: 'Gaming Console' }
]

function subtotal(items) {
    return items.map(({ PC_REP_CURR_COST }) => +PC_REP_CURR_COST).reduce((sum, i) => +sum + +i, 0);
}

class RepairBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,

            RepairModelList: [],
            RepairBrandList: [],
            RepairServiceList: [],
            RepairShopList: [],
            AllServiceData: [],
            TempNotes: [],

            jobData: {
                PC_REP_ID: "",
                PC_REP_BARCODE: "",
                PC_REP_TYPE: "",
                PC_REP_MODEL: "",
                PC_REP_BRAND: "",
                PC_REP_IMSR: "",
                PC_REP_OTHER_INFO: "",
                PC_REP_LIST: [],
                PC_REP_TOTAL_COST: "0",
                PC_REP_DEP_TYPE: "Cash",
                PC_REP_DEP: "0",
                PC_REP_DEP_DATE: "",
                PC_REP_REM_AM_TYPE: "Cash",
                PC_REP_REM_AM: "0",
                PC_REP_REM_AM_DATE: "",
                PC_REP_REM_BAL: "0",
                PC_REP_SHOP: "",
                PC_REP_STATUS: "",
                PC_REP_DATA_TYPE: "",
                PC_REP_NOTES: [],
                PC_REP_APP_DATE: "",
                PC_REP_APP_TIME: "",
                PC_CUST_FR_NAME: "",
                PC_CUST_LT_NAME: "",
                PC_CUST_PHONE: "",
                PC_CUST_EMAIL: "",
                PC_CUST_HSST_NO: "",
                PC_CUST_POST_CODE: "",
                PC_CUST_ADD_INFO: "",
                PC_REP_CREATED_DATE: "",
                PC_REP_UPDATED_DATE: ""
            },


            enableReport: false
        };
    }

    componentDidMount() {
        this.getShopList()
    }

    async getShopList() {
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let RepairShopList = []
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                RepairShopList.push({
                    value: shop.SP_NAME,
                    label: shop.SP_NAME
                })
            }
        }
        this.setState({ RepairShopList })
    }

    validateForm() {
        let { jobData } = this.state;
        if (jobData.PC_REP_TYPE === '' || jobData.PC_REP_TYPE === {}) {
            Swal.fire(
                'Error',
                'Please Select Device Type',
                'error'
            )
            return false
        }
        if (jobData.PC_REP_MODEL === '' || jobData.PC_REP_MODEL === {}) {
            Swal.fire(
                'Error',
                'Please Select Brand',
                'error'
            )
            return false
        }
        if (jobData.PC_REP_BRAND === '' || jobData.PC_REP_BRAND === {}) {
            Swal.fire(
                'Error',
                'Please Select Model',
                'error'
            )
            return false
        }
        if (jobData.PC_REP_LIST.length === 0) {
            Swal.fire(
                'Error',
                'Please Enter Any Repair',
                'error'
            )
            return false
        }

        if (
            jobData.PC_CUST_FR_NAME === '' ||
            jobData.PC_CUST_LT_NAME === '' ||
            jobData.PC_CUST_PHONE === '' ||
            jobData.PC_CUST_EMAIL === '' ||
            jobData.PC_CUST_HSST_NO === '' ||
            jobData.PC_CUST_POST_CODE === ''
        ) {
            Swal.fire(
                'Error',
                'Please Enter Complete Customer Information',
                'error'
            )
            return false
        }
        return true
    }

    async handleNextStep() {
        let { jobData, activeStep } = this.state;
        let RepairServiceList = []
        if (activeStep === 0) {
            let formData = {
                REPAIR_TYPE: jobData.PC_REP_TYPE?.value,
                REPAIR_BRAND: jobData.PC_REP_MODEL?.value,
                REPAIR_DEVICE: jobData.PC_REP_BRAND?.value,
                PC_SHOP: jobData.PC_REP_SHOP?.value
            }
            const getService = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShopService.php`, formData);
            let serv = getService.data
            if (serv.success === 1) {
                for (let i = 0; i < serv.data.length; i++) {
                    const ele = serv.data[i];
                    if (
                        ele.PCSS_DEVICE_TYPE === jobData['PC_REP_TYPE']?.value &&
                        ele.PCSS_DEVICE_BRAND === jobData['PC_REP_MODEL']?.value &&
                        ele.PCSS_DEVICE_NAME === jobData['PC_REP_BRAND']?.value
                    ) {
                        RepairServiceList.push({
                            value: ele.PCSS_SERVICE,
                            label: ele.PCSS_SERVICE
                        })
                    }
                }
            }
            jobData['PC_REP_IMSR'] = ""
            jobData['PC_REP_LIST'] = []
            this.setState({ RepairServiceList, AllServiceData: serv.data })
        }

        if (activeStep === 3) {
            if (!this.validateForm()) return
            let formData = {
                PC_REP_ID: jobData.PC_REP_ID,
                PC_REP_TYPE: JSON.stringify(jobData.PC_REP_TYPE),
                PC_REP_MODEL: JSON.stringify(jobData.PC_REP_MODEL),
                PC_REP_BRAND: JSON.stringify(jobData.PC_REP_BRAND),
                PC_REP_IMSR: jobData.PC_REP_IMSR,
                PC_REP_OTHER_INFO: jobData.PC_REP_OTHER_INFO,
                PC_REP_LIST: JSON.stringify(jobData.PC_REP_LIST),
                PC_REP_TOTAL_COST: jobData.PC_REP_TOTAL_COST,
                PC_REP_DEP_TYPE: jobData.PC_REP_DEP_TYPE,
                PC_REP_DEP: jobData.PC_REP_DEP,
                PC_REP_DEP_DATE: new Date().toLocaleDateString(),
                PC_REP_REM_AM_TYPE: jobData.PC_REP_REM_AM_TYPE,
                PC_REP_REM_AM: jobData.PC_REP_REM_AM,
                PC_REP_REM_AM_DATE: new Date().toLocaleDateString(),
                PC_REP_REM_BAL: jobData.PC_REP_REM_BAL,
                PC_REP_SHOP: jobData.PC_REP_SHOP.value,
                PC_REP_STATUS: 'Request',
                PC_REP_DATA_TYPE: 'Online Customer',
                PC_REP_NOTES: JSON.stringify([...jobData.PC_REP_NOTES, ...this.state.TempNotes]),
                PC_REP_APP_DATE: jobData.PC_REP_APP_DATE,
                PC_REP_APP_TIME: jobData.PC_REP_APP_TIME,
                PC_CUST_FR_NAME: jobData.PC_CUST_FR_NAME,
                PC_CUST_LT_NAME: jobData.PC_CUST_LT_NAME,
                PC_CUST_PHONE: jobData.PC_CUST_PHONE,
                PC_CUST_EMAIL: jobData.PC_CUST_EMAIL,
                PC_CUST_HSST_NO: jobData.PC_CUST_HSST_NO,
                PC_CUST_POST_CODE: jobData.PC_CUST_POST_CODE,
                PC_CUST_ADD_INFO: jobData.PC_CUST_ADD_INFO
            }
            const addNewRepairRequest = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/PCJob.php`, formData);
            if (addNewRepairRequest.data.success === 1) {
                this.setState({
                    enableReport: true
                })
            }
        }
        else {
            this.setState({
                activeStep: activeStep + 1
            })
        }
    }

    handleBackStep() {
        let { activeStep } = this.state
        this.setState({
            activeStep: activeStep - 1
        })
    }

    handleRequestMore() {
        this.setState({
            activeStep: 0,

            RepairModelList: [],
            RepairBrandList: [],
            RepairServiceList: [],
            RepairShopList: [],
            AllServiceData: [],
            TempNotes: [],

            jobData: {
                PC_REP_ID: "",
                PC_REP_BARCODE: "",
                PC_REP_TYPE: "",
                PC_REP_MODEL: "",
                PC_REP_BRAND: "",
                PC_REP_IMSR: "",
                PC_REP_OTHER_INFO: "",
                PC_REP_LIST: [],
                PC_REP_TOTAL_COST: "0",
                PC_REP_DEP_TYPE: "Cash",
                PC_REP_DEP: "0",
                PC_REP_DEP_DATE: "",
                PC_REP_REM_AM_TYPE: "Cash",
                PC_REP_REM_AM: "0",
                PC_REP_REM_AM_DATE: "",
                PC_REP_REM_BAL: "0",
                PC_REP_SHOP: "",
                PC_REP_STATUS: "",
                PC_REP_DATA_TYPE: "",
                PC_REP_NOTES: [],
                PC_REP_APP_DATE: "",
                PC_REP_APP_TIME: "",
                PC_CUST_FR_NAME: "",
                PC_CUST_LT_NAME: "",
                PC_CUST_PHONE: "",
                PC_CUST_EMAIL: "",
                PC_CUST_HSST_NO: "",
                PC_CUST_POST_CODE: "",
                PC_CUST_ADD_INFO: "",
                PC_REP_CREATED_DATE: "",
                PC_REP_UPDATED_DATE: ""
            },


            enableReport: false
        })
    }

    handleAddService() {
        let { jobData } = this.state
        jobData['PC_REP_LIST'].push({
            PC_REP_PROB: "",
            PC_REP_SER_TYPE: { value: 'Express', label: 'Express' },
            PC_REP_EST_COST: 0,
            PC_REP_DISCOUNT: 0,
            PC_REP_CURR_COST: 0
        })
        this.setState({ jobData })
    }

    handleChangeServices(indx, fieldName, evnt) {
        let { AllServiceData, jobData } = this.state;
        let myPercentage, myBalance, fakeBalance
        switch (fieldName) {
            case 'PC_REP_PROB':
                jobData['PC_REP_LIST'][indx][fieldName] = evnt
                jobData['PC_REP_LIST'][indx]['PC_REP_DISCOUNT'] = 0
                if (AllServiceData) {
                    for (let i = 0; i < AllServiceData.length; i++) {
                        const servData = AllServiceData[i];
                        if (servData?.PCSS_SERVICE === evnt?.value) {
                            if (jobData['PC_REP_LIST'][indx]['PC_REP_SER_TYPE']?.value === 'Express') {
                                jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] = +servData.PCSS_SERVICE_COST;
                                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = +servData.PCSS_SERVICE_COST;
                            } else {
                                jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] = +servData.PCSS_SERVICE_PREM;
                                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = +servData.PCSS_SERVICE_PREM;
                            }
                        }
                    }
                }
                break;

            case 'PC_REP_SER_TYPE':
                jobData['PC_REP_LIST'][indx][fieldName] = evnt
                jobData['PC_REP_LIST'][indx]['PC_REP_DISCOUNT'] = 0
                if (AllServiceData) {
                    for (let i = 0; i < AllServiceData.length; i++) {
                        const servData = AllServiceData[i];
                        if (servData?.PCSS_SERVICE === jobData['PC_REP_LIST'][indx]['PC_REP_PROB']?.value) {
                            if (evnt?.value === 'Express') {
                                jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] = +servData.PCSS_SERVICE_COST;
                                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = +servData.PCSS_SERVICE_COST;
                            } else {
                                jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] = +servData.PCSS_SERVICE_PREM;
                                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = +servData.PCSS_SERVICE_PREM;
                            }
                        }
                    }
                }
                break;

            case 'PC_REP_DISCOUNT':
                jobData['PC_REP_LIST'][indx][fieldName] = evnt.target.value

                myPercentage = evnt.target.value / 100
                myBalance = (jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] * myPercentage)
                fakeBalance = jobData['PC_REP_LIST'][indx]['PC_REP_EST_COST'] - myBalance
                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = fakeBalance
                break;

            case 'PC_REP_EST_COST':
                jobData['PC_REP_LIST'][indx][fieldName] = evnt.target.value
                jobData['PC_REP_LIST'][indx]['PC_REP_CURR_COST'] = evnt.target.value
                jobData['PC_REP_LIST'][indx]['PC_REP_DISCOUNT'] = 0
                break;

            default:
                jobData['PC_REP_LIST'][indx][fieldName] = evnt.target.value
                break;
        }
        let subTotals = subtotal(jobData['PC_REP_LIST'])
        jobData['PC_REP_TOTAL_COST'] = (subTotals).toString();
        jobData['PC_REP_REM_BAL'] = (subTotals - jobData['PC_REP_DEP']).toString();
        this.setState({ jobData })
    }

    handleRemoveService(indx) {
        let { jobData } = this.state
        jobData['PC_REP_LIST'].splice(indx, 1)
        this.setState({ jobData })
    }

    async handleChange(fieldName, evnt) {
        let { jobData } = this.state
        switch (fieldName) {
            case 'PC_REP_TYPE':
                jobData[fieldName] = evnt
                let deviceBrand = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetDeviceBrand.php`, { DEVICE: evnt.value });
                let RepairModelList = []
                if (deviceBrand.data.success === 1) {
                    let devices = deviceBrand.data.data;
                    for (let i = 0; i < devices.length; i++) {
                        const device = devices[i];
                        RepairModelList.push({
                            value: device.DEVICE_BRAND,
                            label: device.DEVICE_BRAND
                        })
                    }
                }
                jobData['PC_REP_MODEL'] = ""
                jobData['PC_REP_BRAND'] = ""
                jobData['PC_REP_IMSR'] = ""
                jobData['PC_REP_PROB'] = []
                this.setState({ RepairModelList })
                break;
            case 'PC_REP_MODEL':
                jobData[fieldName] = evnt
                let deviceType = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetDeviceName.php`, { BRAND: evnt.value, DEVICE: jobData['PC_REP_TYPE']?.value });
                let RepairBrandList = []
                if (deviceType.data.success === 1) {
                    let devices = deviceType.data.data;
                    for (let i = 0; i < devices.length; i++) {
                        const deviceName = devices[i];
                        RepairBrandList.push({
                            value: deviceName.DEVICE_NAME,
                            label: deviceName.DEVICE_NAME
                        })
                    }
                }

                jobData['PC_REP_BRAND'] = ""
                jobData['PC_REP_IMSR'] = ""
                jobData['PC_REP_LIST'] = []
                this.setState({ RepairBrandList })

                break;
            case 'PC_REP_BRAND':
            case 'PC_REP_SHOP':
                jobData[fieldName] = evnt
                break;
            default:
                jobData[fieldName] = evnt.target.value
                break;
        }
        this.setState({ jobData })
    }

    render() {
        let { jobData } = this.state;
        return (
            <section style={{ padding: "5%" }}>
                {this.state.enableReport === false ?
                    <Container>
                        <Row style={{ overflowX: "auto" }} >
                            <Col>
                                <Stepper
                                    activeStep={this.state.activeStep}
                                >
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        return (
                                            <Step key={index} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Col>
                        </Row>
                        {this.state.activeStep === 0 ?
                            <section style={{ padding: "1rem" }} >
                                <Container fluid>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Device Type</Form.Label>
                                                <Select
                                                    name="PC_REP_TYPE"
                                                    style={{ padding: "0.25rem" }}
                                                    options={RepairTypeList}
                                                    value={jobData['PC_REP_TYPE']}
                                                    onChange={this.handleChange.bind(this, 'PC_REP_TYPE')}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Brand</Form.Label>
                                                <Select
                                                    name="PC_REP_MODEL"
                                                    style={{ padding: "0.25rem" }}
                                                    options={this.state.RepairModelList}
                                                    value={jobData['PC_REP_MODEL']}
                                                    onChange={this.handleChange.bind(this, 'PC_REP_MODEL')}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Model</Form.Label>
                                                <Select
                                                    name="PC_REP_BRAND"
                                                    style={{ padding: "0.25rem" }}
                                                    options={this.state.RepairBrandList}
                                                    value={jobData['PC_REP_BRAND']}
                                                    onChange={this.handleChange.bind(this, 'PC_REP_BRAND')}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Select Phone Shop</Form.Label>
                                                <CreatableSelect
                                                    name={"PC_REP_SHOP"}
                                                    value={jobData['PC_REP_SHOP']}
                                                    options={this.state.RepairShopList}
                                                    onChange={this.handleChange.bind(this, 'PC_REP_SHOP')}
                                                    style={{ padding: "0.25rem" }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                            : this.state.activeStep === 1 ?
                                <section style={{ padding: "1rem" }} >
                                    <Container fluid>
                                        <Row>
                                            <Col>
                                                <TableContainer className="mb-3">
                                                    <Table size="small" aria-label="a dense table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Repair</TableCell>
                                                                <TableCell>Service Type</TableCell>
                                                                <TableCell>Current Cost</TableCell>
                                                                <TableCell align="right">
                                                                    <IconButton onClick={this.handleAddService.bind(this)} >
                                                                        <AddIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {jobData['PC_REP_LIST'].map((elem, indx) => (
                                                                <TableRow
                                                                    key={indx}
                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                >
                                                                    <TableCell component="th" scope="row">
                                                                        <Form.Group>
                                                                            <Select
                                                                                menuPortalTarget={document.body}
                                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                options={this.state.RepairServiceList}
                                                                                value={elem.PC_REP_PROB}
                                                                                onChange={this.handleChangeServices.bind(this, indx, "PC_REP_PROB")}
                                                                            />
                                                                        </Form.Group>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Form.Group>
                                                                            <Select
                                                                                menuPortalTarget={document.body}
                                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                                options={ServiceTypeOption}
                                                                                value={elem.PC_REP_SER_TYPE}
                                                                                onChange={this.handleChangeServices.bind(this, indx, "PC_REP_SER_TYPE")}
                                                                            />
                                                                        </Form.Group>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Form.Group>
                                                                            <Form.Control
                                                                                disabled={true}
                                                                                value={elem.PC_REP_CURR_COST}
                                                                                onChange={this.handleChangeServices.bind(this, indx, "PC_REP_CURR_COST")}
                                                                            />
                                                                        </Form.Group>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <IconButton onClick={this.handleRemoveService.bind(this, indx)} >
                                                                            <RemoveIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Other Information</Form.Label>
                                                    <Form.Control
                                                        name="PC_REP_OTHER_INFO"
                                                        style={{ padding: "0.25rem" }}
                                                        type={"textarea"}
                                                        value={jobData['PC_REP_OTHER_INFO']}
                                                        onChange={this.handleChange.bind(this, "PC_REP_OTHER_INFO")}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Balance</Form.Label>
                                                    <Form.Control
                                                        value={jobData['PC_REP_REM_BAL']}
                                                        style={{ padding: "0.25rem" }}
                                                        disabled={true}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                                : this.state.activeStep === 2 ?
                                    <section style={{ padding: "1rem" }} >
                                        <Container fluid>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Select Date</Form.Label>
                                                        <Form.Control
                                                            type={"date"}
                                                            name="PC_REP_APP_DATE"
                                                            value={jobData['PC_REP_APP_DATE']}
                                                            onChange={this.handleChange.bind(this, "PC_REP_APP_DATE")}
                                                            style={{ padding: "0.25rem", width: "100%" }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label>Select Time</Form.Label>
                                                        <Form.Control
                                                            type={"time"}
                                                            name="PC_REP_APP_TIME"
                                                            value={jobData['PC_REP_APP_TIME']}
                                                            onChange={this.handleChange.bind(this, "PC_REP_APP_TIME")}
                                                            style={{ padding: "0.25rem", width: "100%" }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </section>
                                    : this.state.activeStep === 3 ?
                                        <section style={{ padding: "1rem" }} >
                                            <Container fluid>
                                                <Row style={{ justifyContent: "space-around" }} >
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control
                                                                name="PC_CUST_FR_NAME"
                                                                style={{ padding: "0.25rem" }}
                                                                value={jobData['PC_CUST_FR_NAME']}
                                                                onChange={this.handleChange.bind(this, "PC_CUST_FR_NAME")}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Phone</Form.Label>
                                                            <Form.Control
                                                                name="PC_CUST_PHONE"
                                                                style={{ padding: "0.25rem" }}
                                                                value={jobData['PC_CUST_PHONE']}
                                                                onChange={this.handleChange.bind(this, "PC_CUST_PHONE")}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>House.No / Street Name</Form.Label>
                                                            <Form.Control
                                                                name="PC_CUST_HSST_NO"
                                                                style={{ padding: "0.25rem" }}
                                                                value={jobData['PC_CUST_HSST_NO']}
                                                                onChange={this.handleChange.bind(this, "PC_CUST_HSST_NO")}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control
                                                                name="PC_CUST_LT_NAME"
                                                                style={{ padding: "0.25rem" }}
                                                                value={jobData['PC_CUST_LT_NAME']}
                                                                onChange={this.handleChange.bind(this, "PC_CUST_LT_NAME")}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Email</Form.Label>
                                                            <Form.Control
                                                                name="PC_CUST_EMAIL"
                                                                style={{ padding: "0.25rem" }}
                                                                value={jobData['PC_CUST_EMAIL']}
                                                                onChange={this.handleChange.bind(this, "PC_CUST_EMAIL")}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Post Code</Form.Label>
                                                            <Form.Control
                                                                name="PC_CUST_POST_CODE"
                                                                style={{ padding: "0.25rem" }}
                                                                value={jobData['PC_CUST_POST_CODE']}
                                                                onChange={this.handleChange.bind(this, "PC_CUST_POST_CODE")}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row style={{ justifyContent: "space-around" }} >
                                                    <Col>
                                                        <Form.Group className="mb-3" >
                                                            <Form.Label>Additional Information</Form.Label>
                                                            <Form.Control
                                                                name="PC_CUST_ADD_INFO"
                                                                style={{ padding: "0.25rem" }}
                                                                type={"textarea"}
                                                                value={jobData['PC_CUST_ADD_INFO']}
                                                                onChange={this.handleChange.bind(this, "PC_CUST_ADD_INFO")}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </section>
                                        : null
                        }
                        <Row>
                            <Col>
                                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        disabled={this.state.activeStep === 0}
                                        onClick={this.handleBackStep.bind(this)}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button onClick={this.handleNextStep.bind(this)}>
                                        {this.state.activeStep === steps.length - 1 ? "Finish" : "Next"}
                                    </Button>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <Container>
                        <ShowReport
                            REPAIR_TYPE={jobData.PC_REP_TYPE}
                            REPAIR_DEVICE={jobData.PC_REP_BRAND}
                            REPAIR_SHOP={jobData.PC_REP_SHOP}
                            REPAIR_DATE={jobData.PC_REP_APP_DATE}
                            REPAIR_TIME={jobData.PC_REP_APP_TIME}
                            PR_FIRST_NAME={jobData.PC_CUST_FR_NAME}
                            PR_PHONE={jobData.PC_CUST_PHONE}
                            PR_LAST_NAME={jobData.PC_CUST_LT_NAME}
                            PR_EMAIL={jobData.PC_CUST_EMAIL}
                        />
                        <Row>
                            <Col>
                                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button onClick={this.handleRequestMore.bind(this)}>
                                        Request More
                                    </Button>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                }
            </section>
        );
    }
}

export default RepairBlock;