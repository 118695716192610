import React, { Component } from "react";
import { Container, Row, Col, Form, Modal } from "react-bootstrap"
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from "axios";
import CreatableSelect from 'react-select/creatable';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import Input from '@mui/material/Input';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
// import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
// import PercentIcon from '@mui/icons-material/Percent';
import Swal from "sweetalert2";
import Select from 'react-select'
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Delete';
import { getISOWeek, formatDate, formatWeek, debounceFunc } from './../../helper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReactToPrint from 'react-to-print';

class WarehousePurchaseDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchGridData: "",
            totalRecordsPerPage: { label: 20, value: 20 },
            recordPerPageList: [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 }
            ],
            paginationLength: 1,
            paginationValue: 1,


            addPurchase: false,
            openModal: false,
            PurchaseInvoiceList: [],
            SaleToShop: "",
            SaleToVendor: "",
            ShopList: [],
            PURCHASE_DATE: formatDate(new Date().toDateString()),
            PURCHASE_TO_TYPE: "Vendor",
            AddedItemList: [],
            Pur_Inv_Week: formatWeek(),
            AllVendorList: [],
            VendorList: [],
            shopPaymentDays: [],
            Item: {
                ItemType: "",
                ItemBrand: "",
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemStatus: 'Available',
                ItemSellRate: 0,
                ItemAmount: 0,
                ItemBulk: []
            },
            ItemList: [],

            repairTypeList: [
                { value: 'Phone', label: 'Phone' },
                { value: 'Tablet', label: 'Tablet' },
                { value: 'Laptop', label: 'Laptop' },
                { value: 'Gaming Console', label: 'Gaming Console' }
            ],
            repairBrandList: [],
            repairDeviceList: [],
            BulkImeiRender: [],
            BulkImei: "",
            BulkItemIndex: "",
            EMAIL_TO: ""
        }
    }

    componentDidMount() {
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getAllPurchaseInv(searchGridData, totalRecordsPerPage.value, paginationValue)
    }

    async getAllPurchaseInv(searchGridData, totalRecordsPerPage, recordOffset) {
        this.props.toggleLoader(true)
        let formData = {
            WH_ID: localStorage.getItem('PC_GRP_ATT'),
            searchGridData,
            totalRecordsPerPage,
            recordOffset
        };
        let PurchaseInvoiceList = []
        let paginationLength = 1
        try {
            const getSP = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/SaleNPurchase/PCGetGridPurchaseInv.php`, formData);
            let ShopPaymentData = getSP.data

            if (ShopPaymentData.success === 1) {
                PurchaseInvoiceList = ShopPaymentData.data
                paginationLength = parseInt(getSP.data.Page_count[0]['TOTAL_PAGE_COUNT'] / totalRecordsPerPage) + 1
            }
        } catch (error) {
            console.error('error', error)
            this.props.toggleLoader(false)
        }
        this.props.toggleLoader(false)
        this.setState({ PurchaseInvoiceList, paginationLength })
    }

    async getVenorList() {
        let formData = {
            PC_SP_ID: localStorage.getItem('PC_GRP_ATT'),
            PC_GRP_ID: localStorage.getItem('PC_GRP_ID')
        }
        const getDevice = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCVendor.php`, formData);
        let deviceData = getDevice.data
        let AllVendorList = [], VendorList = []

        if (deviceData.success === 1) {
            AllVendorList = deviceData.data;
            for (let i = 0; i < AllVendorList.length; i++) {
                const vendr = AllVendorList[i];
                VendorList.push({
                    value: vendr.PC_VEN_ID,
                    label: vendr.PC_VEN_NAME
                })
            }
        }
        this.setState({ AllVendorList, VendorList })
    }


    handleChangeTotalRecords(selectValue) {
        this.setState({ totalRecordsPerPage: selectValue })
        let { searchGridData } = this.state
        this.getAllPurchaseInv(searchGridData, selectValue.value, 1)
    }

    handleSearchgridData = debounceFunc(async (MyEvt) => {
        this.setState({ searchGridData: MyEvt.target.value, totalRecordsPerPage: { label: 20, value: 20 } })
        this.getAllPurchaseInv(MyEvt.target.value, 20, 1)
    })


    handleChangePagination(evt, value) {
        this.setState({ paginationValue: value })
        let { totalRecordsPerPage, searchGridData } = this.state;
        this.getAllPurchaseInv(searchGridData, totalRecordsPerPage.value, value)
    }

    handleRefresh() {
        let { totalRecordsPerPage } = this.state
        this.getAllPurchaseInv("", totalRecordsPerPage.value, 1)
    }

    async handleAddPurchaseInvoice() {
        this.getVenorList()
        let a = formatWeek()
        let weekYearArr = a.split("-");
        weekYearArr[1] = weekYearArr[1].replace("W", "");
        let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
        let WeekOut = await getISOWeek(...MappedValue)
        let shopPaymentDays = []
        if (WeekOut.length > 0) {
            for (let i = 0; i < WeekOut.length; i++) {
                const WeekPerDay = WeekOut[i];
                shopPaymentDays.push({
                    'DateAndDay': WeekPerDay
                })
            }
        }

        this.setState({
            shopPaymentDays,
            addPurchase: true,
            isEdit: false
        })

    }

    renderTotalAmount() {
        let { AddedItemList } = this.state;
        let Price = AddedItemList.map(({ ItemAmount }) => ItemAmount).reduce((sum, i) => sum + i, 0);
        return Price
    }


    async handleChangeUpdatedItem(fieldName, evt) {
        let { Item } = this.state;
        Item['ItemQuantity'] = 1
        switch (fieldName) {
            case 'ItemType':
                Item['ItemType'] = evt
                let deviceBrand = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetDeviceBrand.php`, { DEVICE: evt.value });
                let repairBrandList = []
                if (deviceBrand.data.success === 1) {
                    let devices = deviceBrand.data.data;
                    for (let i = 0; i < devices.length; i++) {
                        const device = devices[i];
                        repairBrandList.push({
                            value: device.DEVICE_BRAND,
                            label: device.DEVICE_BRAND
                        })
                    }
                }
                this.setState({ repairBrandList })
                break;
            case "ItemBrand":
                Item['ItemBrand'] = evt
                let deviceType = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/PCGetDeviceName.php`, { BRAND: evt.value, DEVICE: Item['ItemType'].value });
                let repairDeviceList = []
                if (deviceType.data.success === 1) {
                    let devices = deviceType.data.data;
                    for (let i = 0; i < devices.length; i++) {
                        const deviceName = devices[i];
                        repairDeviceList.push({
                            value: deviceName.DEVICE_NAME,
                            label: deviceName.DEVICE_NAME
                        })
                    }
                }
                this.setState({ repairDeviceList })
                break;

            case 'ItemName':
                Item['ItemName'] = evt
                Item['ItemRate'] = 0
                Item['ItemSellRate'] = 0
                Item['ItemAmount'] = 0
                break;

            case 'ItemQuantity':
                Item[evt.target.name] = evt.target.value
                Item['ItemAmount'] = (evt.target.value * Item['ItemRate'])
                break;
            case 'ItemRate':
                Item[evt.target.name] = evt.target.value
                Item['ItemAmount'] = (Item['ItemQuantity'] * evt.target.value)
                break;

            default:
                Item[evt.target.name] = evt.target.value
                break;

        }
        this.setState({ Item })
    }

    async getShopList() {
        const getShops = await axios.get(`${process.env.REACT_APP_BASE_URL}services/query/PCGetShops.php`);
        let shopData = getShops.data
        let ShopList = []
        if (shopData.success === 1) {
            for (let i = 0; i < shopData.data.length; i++) {
                const shop = shopData.data[i];
                ShopList.push({
                    value: shop.SP_NAME,
                    label: shop.SP_NAME
                })
            }
        }
        this.setState({ ShopList })
    }

    async handleChange(fieldName, evt) {
        let target;
        switch (fieldName) {
            case 'PURCHASE_TO_TYPE':
                target = evt.target.value
                this.getShopList()
                break;
            case 'SaleToVendor':
            case 'SaleToShop':
                target = evt
                break;

            case "Pur_Inv_Week":
                target = evt.target.value
                let WeekNumber = evt.target.value;
                let weekYearArr = WeekNumber.split("-");
                weekYearArr[1] = weekYearArr[1].replace("W", "");
                let MappedValue = weekYearArr.map(n => parseInt(n)).reverse();
                let WeekOut = await getISOWeek(...MappedValue)
                let shopPaymentDays = []
                if (WeekOut.length > 0) {
                    for (let i = 0; i < WeekOut.length; i++) {
                        const WeekPerDay = WeekOut[i];
                        shopPaymentDays.push({
                            'DateAndDay': WeekPerDay
                        })
                    }
                }
                this.setState({ shopPaymentDays })
                break;

            default:
                target = evt.target.value
                break;
        }

        this.setState({ [fieldName]: target })

    }


    handleValidateItem(Item, AddedItemList) {
        if (Item.ItemName === '' || Item?.ItemName?.value === "") {
            Swal.fire(
                'Error',
                'Please select Item',
                'error'
            )
            return false
        }
        if (Item.ItemRate === '' || parseInt(Item.ItemRate) <= 0) {
            Swal.fire(
                'Error',
                'Rate must be greater than 0',
                'error'
            )
            return false
        }
        if (Item.ItemQuantity === '' || parseInt(Item.ItemQuantity) <= 0) {
            Swal.fire(
                'Error',
                'Quantity must be greater than 0',
                'error'
            )
            return false
        }

        for (let i = 0; i < AddedItemList.length; i++) {
            const ItemList = AddedItemList[i];
            if (Item.ItemIMEI === ItemList.ItemIMEI) {
                Swal.fire(
                    'Error',
                    'Duplicate IMEI not allowed',
                    'error'
                )
                return false
            }
        }

        for (let j = 0; j < AddedItemList.length; j++) {
            const ItemList = AddedItemList[j];
            for (let k = 0; k < ItemList.ItemBulk.length; k++) {
                const ItemBulk = ItemList.ItemBulk[k];
                if (ItemBulk.BulkImei === Item.ItemIMEI) {
                    Swal.fire(
                        'Error',
                        'Duplicate IMEI not allowed',
                        'error'
                    )
                    return false
                }
            }
        }

        return true
    }

    handleAddItem() {
        let { Item, AddedItemList } = this.state
        if (!this.handleValidateItem(Item, AddedItemList)) return

        axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getUniqueIMEIByWarehouse.php`, { BulkIMEI: Item.ItemIMEI }).then(res => {
            if (res.data.success === 1) {
                Swal.fire(
                    'Error',
                    'Duplicate IMEI not allowed',
                    'error'
                )
            } else {
                let checkIfItemAvailable = AddedItemList.filter(x => x.ItemName.value === Item.ItemName.value)
                let CheckIndex = AddedItemList.findIndex(x => x.ItemName.value === Item.ItemName.value);
                if (checkIfItemAvailable.length > 0) {
                    AddedItemList[CheckIndex]['ItemBulk'].push({
                        BulkImei: Item['ItemIMEI']
                    })
                    this.setState({ AddedItemList })
                } else {
                    let UpdateItemList = [Item].concat(AddedItemList)
                    this.setState({ AddedItemList: UpdateItemList })
                }
                let RefreshItem = {
                    ItemType: "",
                    ItemBrand: "",
                    ItemName: "",
                    ItemIMEI: "",
                    ItemDesc: "",
                    ItemRate: 0,
                    ItemQuantity: 0,
                    ItemStatus: 'Available',
                    ItemSellRate: 0,
                    ItemAmount: 0,
                    ItemBulk: []
                }
                this.setState({ Item: RefreshItem })

            }
        })

    }

    closeModal() {
        this.setState({ openModal: false })
    }

    handleClickBS(index) {
        let { AddedItemList } = this.state;
        let Item = AddedItemList[index]

        this.setState({
            openModal: true,
            BulkItemIndex: index,
            BulkImeiRender: Item['ItemBulk']
        })
    }

    handleValidateUnique(AddedItemList, BulkIMEI) {

        for (let i = 0; i < AddedItemList.length; i++) {
            const itemList = AddedItemList[i];
            if (itemList.ItemIMEI === BulkIMEI) {
                Swal.fire(
                    'Error',
                    'Duplicate IMEI not allowed',
                    'error'
                )
                return false
            }
        }

        for (let j = 0; j < AddedItemList.length; j++) {
            const itemList = AddedItemList[j];
            for (let k = 0; k < itemList.ItemBulk.length; k++) {
                const ItemBulk = itemList.ItemBulk[k];
                if (ItemBulk.BulkImei === BulkIMEI) {
                    Swal.fire(
                        'Error',
                        'Duplicate IMEI not allowed',
                        'error'
                    )
                    return false
                }
            }
        }
        return true
    }

    handleEnterBulkImei(evnt) {
        if (evnt.key === 'Enter') {
            this.handleAddBulkItem()
        }
    }

    handleKeyPress(evnt) {
        if (evnt.key === 'Enter') {
            this.handleAddItem()
        }
    }

    handleChangeBulkScan(evt) {
        this.setState({ [evt.target.name]: evt.target.value })
    }

    async handleAddBulkItem() {
        let { BulkItemIndex, AddedItemList, BulkImei } = this.state;
        if (!this.handleValidateUnique(AddedItemList, BulkImei)) return
        axios.post(`${process.env.REACT_APP_BASE_URL}services/query/getUniqueIMEIByWarehouse.php`, { BulkIMEI: BulkImei }).then(res => {
            if (res.data.success === 1) {
                Swal.fire(
                    'Error',
                    'Duplicate IMEI not allowed',
                    'error'
                )
            } else {
                let Item = AddedItemList[BulkItemIndex]
                Item.ItemBulk.push({ BulkImei })
                this.setState({ AddedItemList, BulkImei: "" })
            }
        })
    }

    handleValidateData() {
        let { AddedItemList, PURCHASE_TO_TYPE } = this.state;

        if (PURCHASE_TO_TYPE === "Vendor" && this.state.SaleToVendor === "") {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                'Please select Vendor',
                'error'
            )
            return false
        }

        if (PURCHASE_TO_TYPE === "Shop" && this.state.SaleToShop === "") {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                'Please select Shop',
                'error'
            )
            return false
        }

        if (AddedItemList.length === 0) {
            this.props.toggleLoader(false)
            Swal.fire(
                'Error',
                'Please add atleast 1 item',
                'error'
            )
            return false
        }
        return true
    }

    async handleSave() {
        let { PURCHASE_TO_TYPE } = this.state;
        this.props.toggleLoader(true)
        if (!this.handleValidateData()) return

        let formData = {
            PUR_INV_ID: "",
            PUR_INV_WHID: localStorage.getItem('PC_GRP_ATT'),
            PUR_INV_DAYS: JSON.stringify(this.state.shopPaymentDays),
            PUR_INV_WEEK: this.state.Pur_Inv_Week,
            PUR_INV_TO: PURCHASE_TO_TYPE === "Vendor" ? this.state.SaleToVendor?.label : this.state.SaleToShop?.label,
            PUR_INV_TOTYPE: this.state.PURCHASE_TO_TYPE,
            PUR_INV_DATE: this.state.PURCHASE_DATE,
            PUR_INV_ITEM: JSON.stringify(this.state.AddedItemList),
            PUR_INV_NETAMT: this.renderTotalAmount()
        }

        console.log('formData', formData)

        try {

            let getPurInvRes = await axios.post(`${process.env.REACT_APP_BASE_URL}services/mutation/SaleNPurchase/PCAddWarehousePurchase.php`, formData);
            console.log('getPurInvRes', getPurInvRes)
            this.props.toggleLoader(false)
            if (getPurInvRes.data.status === 200) {
                Swal.fire(
                    'Success',
                    getPurInvRes.data.message,
                    'success'
                )
            } else {
                Swal.fire(
                    'Error',
                    getPurInvRes.data.message,
                    'error'
                )
            }
            this.handleExit()
        } catch (error) {
            this.props.toggleLoader(false)
            console.error('error :>> ', error);
        }
    }

    handleExit() {
        this.setState({
            addPurchase: false,
            openModal: false,
            SaleToShop: "",
            PurchaseInvoiceList: [],
            SaleToVendor: "",
            ShopList: [],
            PURCHASE_DATE: formatDate(new Date().toDateString()),
            PURCHASE_TO_TYPE: "Vendor",
            AddedItemList: [],
            Pur_Inv_Week: formatWeek(),
            AllVendorList: [],
            VendorList: [],
            shopPaymentDays: [],
            Item: {
                ItemType: "",
                ItemBrand: "",
                ItemName: "",
                ItemIMEI: "",
                ItemDesc: "",
                ItemRate: 0,
                ItemQuantity: 0,
                ItemStatus: 'Available',
                ItemSellRate: 0,
                ItemAmount: 0,
                ItemBulk: []
            },
            InventoryList: [],
            ItemList: [],

            repairTypeList: [
                { value: 'Phone', label: 'Phone' },
                { value: 'Tablet', label: 'Tablet' },
                { value: 'Laptop', label: 'Laptop' },
                { value: 'Gaming Console', label: 'Gaming Console' }
            ],
            repairBrandList: [],
            repairDeviceList: [],
            BulkImeiRender: [],
            BulkImei: "",
            BulkItemIndex: "",
            EMAIL_TO: ""
        })
        let { searchGridData, totalRecordsPerPage, paginationValue } = this.state
        this.getAllPurchaseInv(searchGridData, totalRecordsPerPage.value, paginationValue)
    }


    handleValidate() {
        if (this.state.EMAIL_TO === "") {
            Swal.fire(
                'Error',
                'Please Enter Email Address',
                'error'
            )
            return false
        }
        return true
    }

    async handleSendEmail() {

        if (!this.handleValidate()) return

        let formData = {
            SALE_BY: localStorage.getItem('PC_USR_NAME'),
            SALE_FROM: localStorage.getItem('PC_GRP_ATT'),
            SALE_CUSTOMER: this.state.Customer,
            // SALE_WAREHOUSE: this.state.Warehouse.value,
            SALE_DATE: this.state.Date,
            SALE_DESCRIPTION: this.state.Description,
            SALE_ITEM: JSON.stringify(this.state.AddedItemList),
            EMAIL_TO: this.state.EMAIL_TO
        }
        let getSaleInvoiceResult = await axios.post(`${process.env.REACT_APP_BASE_URL}mail/SendMail.php`, formData);
        if (getSaleInvoiceResult.data.status === 201) {
            Swal.fire(
                'Success',
                'Email has been send!!',
                'success'
            )
        }
    }

    async handleEdit(index) {
        let { PurchaseInvoiceList } = this.state;
        let Data = PurchaseInvoiceList[index];

        let AddedItemList = []
        try {
            let getPurInvRes = await axios.post(`${process.env.REACT_APP_BASE_URL}services/query/SaleNPurchase/PCGetDetailPurchaseInv.php`, {
                PC_WH_INV_WHID: localStorage.getItem('PC_GRP_ATT'),
                PC_WH_INV_PUR_ID: Data.PUR_INV_ID
            });
            if (getPurInvRes.data.success === 1) {
                for (let i = 0; i < getPurInvRes.data.data.length; i++) {
                    const ele = getPurInvRes.data.data[i];
                    ele['ItemAmount'] = +ele['ItemRate']
                    ele['ItemName'] = { value: ele['ItemName'], label: ele['ItemName'] }
                }
            }
            AddedItemList = getPurInvRes.data.data
        } catch (error) {
            console.log('error', error)
        }

        this.setState({
            isEdit: true,
            addPurchase: true,
            shopPaymentDays: JSON.parse(Data.PUR_INV_DAYS),
            Pur_Inv_Week: Data.PUR_INV_WEEK,
            PURCHASE_TO_TYPE: Data.PUR_INV_TOTYPE,
            SaleToVendor: { value: Data.PUR_INV_TO, label: Data.PUR_INV_TO },
            SaleToShop: { value: Data.PUR_INV_TO, label: Data.PUR_INV_TO },
            PURCHASE_DATE: Data.PUR_INV_DATE,
            AddedItemList
        })
    }

    handleRemove(indx) {
        let { AddedItemList } = this.state;
        AddedItemList.splice(indx, 1)
        this.setState({ AddedItemList })
    }

    handleRemoveBulk(index) {
        let { BulkItemIndex, AddedItemList } = this.state;
        let Item = AddedItemList[BulkItemIndex]
        Item.ItemBulk.splice(index, 1)
        this.setState({ AddedItemList, BulkImei: "" })
    }

    render() {
        let { shopPaymentDays, Item } = this.state;
        return (
            <Container>
                {this.state.addPurchase === false ?
                    <>
                        <Row style={{ margin: "5px" }} >
                            <Col md={6} style={{ textAlign: "start" }} >
                                <FormControl variant="standard" >
                                    <Input
                                        onChange={this.handleSearchgridData.bind(this)}
                                        id="input-with-icon-adornment"
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Search style={{ color: '#111827' }} />
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6} style={{ textAlign: "end" }} >
                                <IconButton aria-label="refresh" onClick={this.handleRefresh.bind(this)} >
                                    <RefreshIcon />
                                </IconButton>
                                <Button className="add-button" size="small" variant="contained" onClick={this.handleAddPurchaseInvoice.bind(this)}>New Purchase</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '70vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Purchase Date</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Purchase To Type</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Purchase To</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.PurchaseInvoiceList.map((device, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={this.handleEdit.bind(this, idx)}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">{device.PUR_INV_DATE}</TableCell>
                                                    <TableCell component="th" scope="row">{device.PUR_INV_TOTYPE}</TableCell>
                                                    <TableCell component="th" scope="row">{device.PUR_INV_TO}</TableCell>
                                                    <TableCell component="th" scope="row">{device.PUR_INV_NETAMT}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Row>
                        <Row>
                            <Col md={6} style={{ textAlign: "start" }}>
                                <FormControl style={{ width: '20%' }} >
                                    <Select
                                        menuPlacement="auto"
                                        style={{ height: '32px' }}
                                        value={this.state.totalRecordsPerPage || 20}
                                        options={this.state.recordPerPageList}
                                        onChange={this.handleChangeTotalRecords.bind(this)}
                                    />
                                </FormControl>
                            </Col>
                            <Col md={6}>
                                <Pagination
                                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                                    count={this.state.paginationLength}
                                    onChange={this.handleChangePagination.bind(this)}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            components={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    <div>
                        <Row>
                            <legend style={{ borderBottom: '1px solid #1c1950' }} >Purchase By Warehouse</legend>
                        </Row>
                        <Row>
                            <Col md={4} style={{ alignSelf: "end" }}  >
                                <Form.Group className="mb-3" >
                                    <Form.Label style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
                                        <span>Week</span>
                                        <span>
                                            {shopPaymentDays.length > 0 ? `(${shopPaymentDays[0]['DateAndDay']} - ${shopPaymentDays[6]['DateAndDay']})` : ``}
                                        </span>
                                    </Form.Label>
                                    <Form.Control
                                        style={{ padding: "0.25rem" }}
                                        type={"week"}
                                        disabled={this.state.isEdit === true && this.state.addPurchase === true ? true : false}
                                        value={this.state.Pur_Inv_Week}
                                        onChange={this.handleChange.bind(this, "Pur_Inv_Week")}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={5} >
                                <Form.Group className="mb-3" >
                                    <Form.Label style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: "end" }} >
                                        <div>
                                            {this.state.PURCHASE_TO_TYPE}
                                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                        </div>
                                        <div>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={this.state.PURCHASE_TO_TYPE}
                                                exclusive
                                                disabled={this.state.isEdit && this.state.addPurchase === true ? true : false}
                                                size="small"
                                                name="PURCHASE_TO_TYPE"
                                                onChange={this.handleChange.bind(this, 'PURCHASE_TO_TYPE')}
                                                aria-label="small button group"
                                            >
                                                <ToggleButton value="Vendor">Vendor</ToggleButton>
                                                <ToggleButton value="Shop">Shop</ToggleButton>
                                            </ToggleButtonGroup>
                                        </div>
                                    </Form.Label>
                                    {this.state.PURCHASE_TO_TYPE === "Vendor" ?
                                        <CreatableSelect
                                            menuPlacement="auto"
                                            style={{ height: '32px' }}
                                            isDisabled={this.state.isEdit && this.state.addPurchase === true ? true : false}
                                            value={this.state.SaleToVendor}
                                            options={this.state.VendorList}
                                            onChange={this.handleChange.bind(this, "SaleToVendor")}
                                        />
                                        :
                                        <Select
                                            menuPlacement="auto"
                                            style={{ height: '32px' }}
                                            isDisabled={this.state.isEdit && this.state.addPurchase === true ? true : false}
                                            value={this.state.SaleToShop}
                                            options={this.state.ShopList}
                                            onChange={this.handleChange.bind(this, "SaleToShop")}
                                        />
                                    }
                                </Form.Group>
                            </Col>
                            <Col md={3} style={{ alignSelf: "end" }}  >
                                <Form.Group className="mb-3" >
                                    <Form.Label style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
                                        <span>Date</span>
                                    </Form.Label>
                                    <Form.Control
                                        name="PURCHASE_DATE"
                                        style={{ padding: "0.25rem" }}
                                        type="date"
                                        disabled={this.state.isEdit && this.state.addPurchase === true ? true : false}
                                        min={`${shopPaymentDays.length > 0 ? formatDate(shopPaymentDays[0]['DateAndDay']) : ''}`}
                                        max={`${shopPaymentDays.length > 0 ? formatDate(shopPaymentDays[6]['DateAndDay']) : ''}`}
                                        value={this.state.PURCHASE_DATE}
                                        onChange={this.handleChange.bind(this, 'PURCHASE_DATE')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {this.state.isEdit === false ?
                            <Row style={{ margin: "5px" }} >
                                <Col md={11} >
                                    <Row>
                                        <Col md={3}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Device</Form.Label>
                                                <Select
                                                    style={{ padding: "0.25rem" }}
                                                    // isDisabled={this.state.PCSS_ID === "" ? false : true}
                                                    options={this.state.repairTypeList}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemType')}
                                                    value={Item['ItemType']}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Brand</Form.Label>
                                                <Select
                                                    options={this.state.repairBrandList}
                                                    // isDisabled={this.state.PCSS_ID === "" ? false : true}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemBrand')}
                                                    style={{ padding: "0.25rem" }}
                                                    value={Item['ItemBrand']}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Item</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Select
                                                    name={"ItemName"}
                                                    menuPlacement="auto"
                                                    style={{ height: '32px' }}
                                                    value={Item['ItemName']}
                                                    options={this.state.repairDeviceList}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemName')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>IMEI No</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemIMEI"}
                                                    value={Item["ItemIMEI"]}
                                                    style={{ padding: "0.25rem" }}
                                                    onKeyPress={this.handleKeyPress.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemIMEI')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Buy Rate</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemRate"}
                                                    value={Item["ItemRate"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    onKeyPress={this.handleKeyPress.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemRate')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Sell Rate</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemSellRate"}
                                                    value={Item["ItemSellRate"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    onKeyPress={this.handleKeyPress.bind(this)}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemSellRate')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={1}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Qty.</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemQuantity"}
                                                    value={Item["ItemQuantity"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    disabled
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemQuantity')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Description</Form.Label>
                                                <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                                                <Form.Control
                                                    name={"ItemDesc"}
                                                    value={Item["ItemDesc"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"textarea"}
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemDesc')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={1}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Amount</Form.Label>
                                                <Form.Control
                                                    name={"ItemAmount"}
                                                    value={Item["ItemAmount"]}
                                                    style={{ padding: "0.25rem" }}
                                                    type={"number"}
                                                    disabled
                                                    onChange={this.handleChangeUpdatedItem.bind(this, 'ItemAmount')}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={1} style={{ alignSelf: 'center' }} >
                                    <Button style={{ background: 'green', color: 'white' }} onClick={this.handleAddItem.bind(this)} > Add </Button>
                                </Col>
                            </Row>
                            :
                            ""
                        }
                        <Row style={{ height: "30vh", overflow: "auto" }} >
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: '25vh' }}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Name</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >IMEI/Barcode</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Buy Rate</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Sell Rate</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Quantity</TableCell>
                                                <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >Amount</TableCell>
                                                {!this.state.isEdit ?
                                                    <TableCell style={{ background: '#f1f1f1', color: '#111827' }} >
                                                        <IconButton aria-label="Edit Data" component="span">
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                    :
                                                    ""
                                                }
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {this.state.AddedItemList.map((ItemDetails, idx) => (
                                                <TableRow
                                                    className="table-rows"
                                                    key={idx}
                                                    style={{ cursor: 'pointer' }}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell>{ItemDetails.ItemName?.label ? ItemDetails.ItemName?.label : ItemDetails.ItemName}
                                                        {this.state.isEdit === false ?
                                                            <span style={{ marginLeft: '15px', color: 'blue', textDecoration: 'underline' }} onClick={this.handleClickBS.bind(this, idx)} >BS</span>
                                                            :
                                                            ""
                                                        }
                                                    </TableCell>
                                                    <TableCell >{ItemDetails.ItemIMEI}</TableCell>
                                                    <TableCell >£ {ItemDetails.ItemRate}</TableCell>
                                                    <TableCell >£ {ItemDetails.ItemSellRate}</TableCell>
                                                    <TableCell >{this.state.isEdit === false ? ItemDetails.ItemBulk.length === 0 ? 1 : ItemDetails.ItemBulk.length + 1 : 1}</TableCell>
                                                    <TableCell >£ {ItemDetails.ItemAmount}</TableCell>
                                                    {!this.state.isEdit ?
                                                        <TableCell >
                                                            <IconButton aria-label="Edit Data" component="span" onClick={this.handleRemove.bind(this, idx)} >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                        : ""
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                    <TableBody>
                                        <TableRow
                                            className="table-rows"
                                            style={{ cursor: 'pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell style={{ background: '#f1f1f1', color: '#111827' }} rowSpan={4} colSpan={3}>Net Sale</TableCell>
                                            <TableCell>£ {this.renderTotalAmount()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Row>
                        <Row>
                            <Col md={6} >
                                <Button onClick={this.handleExit.bind(this)} >Exit</Button>
                                {!this.state.isEdit ?
                                    <Button className="add-button" size="small" variant="contained" onClick={this.handleSave.bind(this)} >Save</Button>
                                    :
                                    ""
                                }
                            </Col>
                            {this.state.isEdit ?
                                <Col md={6} style={{ textAlign: 'end' }} >
                                    <div style={{ display: "flex", justifyContent: "space-between" }} >
                                        <Form.Control
                                            style={{ padding: "0.25rem", width: "50%" }}
                                            value={this.state.EMAIL_TO}
                                            placeholder="Enter Email"
                                            onChange={this.handleChange.bind(this, "EMAIL_TO")}
                                        />
                                        <div>
                                            <Button onClick={this.handleSendEmail.bind(this)} >Send Email</Button>
                                            <ReactToPrint
                                                trigger={() => { return <Button style={{ background: '#ff7f19', color: "white" }}  >Print</Button> }}
                                                content={() => this.printThermalSale}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                :
                                ""
                            }
                        </Row>
                    </div>
                }


                <Modal
                    show={this.state.openModal}
                    onHide={this.closeModal.bind(this)}
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton >
                        <Modal.Title> Bulk Scan IMEI No </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" >
                            <Form.Label>IMEI No</Form.Label>
                            <span style={{ color: 'red', marginLeft: '15px' }} >{'*'}</span>
                            <Form.Control
                                name={"BulkImei"}
                                value={this.state.BulkImei}
                                style={{ padding: "0.25rem" }}
                                onKeyPress={this.handleEnterBulkImei.bind(this)}
                                onChange={this.handleChangeBulkScan.bind(this)}
                            />
                        </Form.Group>
                        <Table>
                            <TableBody>
                                {this.state.BulkImeiRender.map((bulk, bulkKeyIndex) => {
                                    return (
                                        <TableRow
                                            className="table-rows"
                                            key={bulkKeyIndex}
                                            style={{ cursor: 'pointer' }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell>{bulkKeyIndex + 1}</TableCell>
                                            <TableCell>{bulk.BulkImei}</TableCell>
                                            <TableCell>
                                                <IconButton aria-label="Edit Data" component="span" onClick={this.handleRemoveBulk.bind(this, bulkKeyIndex)} >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </Container>
        );
    }
}

export default WarehousePurchaseDevice;